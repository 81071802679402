<template>
  <div class="card">
    <div class="card-body">
      <div class="row success">
        <div class="col-xl-8">
          <h5 style="color: blue; margin-bottom: 30px">
            <span style="margin-right: 30px"> {{ $t("bulletin.notes") }}</span>
            <a-tooltip
              placement="right"
              v-if="selectedClasse && selectedTrimester"
            >
              <template slot="title">
                {{
                  isValidatedBulletin()
                    ? $t("bulletin.hideBulletin")
                    : $t("bulletin.validateBulletin")
                }}
              </template>
              <span>
                <a-button
                  :type="isValidatedBulletin() ? 'primary' : 'danger'"
                  @click="confirmeBulletinValidation()"
                >
                  {{
                    isValidatedBulletin()
                      ? $t("bulletin.cancelValidation")
                      : $t("bulletin.validatedClassroom")
                  }}
                </a-button>
              </span>
            </a-tooltip>
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3">
          <label>{{ $t("bulletin.listeClasses") }}</label>
          <a-select
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 150px; margin-left: 10px"
            @change="
              (val) => {
                handleClassChange('classe', val);
              }
            "
          >
            <a-select-option value="all"> --- </a-select-option>
            <a-select-option
              v-for="classe in classes"
              :key="classe._id"
              :value="classe._id"
            >
              {{ classe.name }}
            </a-select-option>
          </a-select>
        </div>

        <div class="col-xl-3">
          <label style="margin-right: 5px">{{ $t("menu.eleves") }}</label>
          <a-select
            :placeholder="$t('scolarite.listeMatieres')"
            style="width: 150px; margin-left: 10px"
            :loading="studentLoading"
            v-model="selectedStudent"
            @change="
              (val) => {
                this.selectedStudent = val;
                this.selectedStudentName = this.activeData.find(
                  (s) => s._id == val
                ).fullName;
                this.updateStudentMarks();
              }
            "
          >
            <a-select-option
              v-for="student in activeData"
              :key="student._id"
              :value="student._id"
            >
              {{ student.fullName }}
            </a-select-option>
          </a-select>
        </div>

        <div class="col-xl-3">
          <label style="margin-right: 5px">{{
            this.$t("emploi.trimestre")
          }}</label>
          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100px; margin-left: 15px; margin-right: 20px"
            @change="
              (val) => {
                this.selectedTrimester = val;
                handleClassChange('classe', this.selectedClasse);
                this.updateStudentMarks();
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>
        </div>

        <div class="col-xl-3">
          <label style="margin-right: 15px" v-if="selectedClasse">{{
            this.$t("menu.bulletin")
          }}</label>
          <a-dropdown style="margin-right: 5px" v-if="selectedClasse">
            <template #overlay>
              <a-menu
                @click="
                  (val) => {
                    if (val.key == '1') convert();
                    else if (val.key == '2') printAllBulletins();
                    else if (val.key == '3') printPVBulletin();
                    else if (val.key == '4') printPVBulletinLevel();
                    else if (val.key == '5') prePrintPedaOne();
                    else if (val.key == '6') printAllBulletinsPeda();
                    else if (val.key == '7') openFinalBulletin();
                    else if (val.key == '8') openAllFinalBulletin();
                    else if (val.key == '9') printPVAnnuel();
                    else if (val.key == '10') printPVAnnuelByLevel();
                  }
                "
              >
                <a-menu-item-group key="g1" title="Bulletins">
                  <a-menu-item key="1" v-if="selectedStudent">
                    {{ $t("bulletin.imprimerBulletin") }}
                  </a-menu-item>
                  <a-menu-item key="2">
                    {{ $t("bulletin.imprimerTout") }}
                  </a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group
                  key="g3"
                  title="Bulletins (format pédagogie)"
                >
                  <a-menu-item key="5" v-if="selectedStudent">
                    {{ $t("bulletin.imprimerBulletin") }}
                  </a-menu-item>
                  <a-menu-item key="6">
                    {{ $t("bulletin.imprimerTout") }}
                  </a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group key="g4" title="Bulletin annuel">
                  <a-menu-item key="7" v-if="selectedStudent">{{
                    $t("Imprimer bulletin")
                  }}</a-menu-item>
                  <a-menu-item key="8">{{ $t("Imprimer tout") }}</a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group key="g2" title="PV Bulletins">
                  <a-menu-item key="3">Imprimer PV</a-menu-item>
                  <a-menu-item key="4">Imprimer PV par niveau</a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group key="g8" title="PV Annuel">
                  <a-menu-item key="9">Imprimer PV</a-menu-item>
                  <a-menu-item key="10">Imprimer PV par niveau</a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
            <a-button
              type="primary"
              :loading="printLoading"
              :disabled="printLoading"
            >
              <a-icon v-if="!printLoading" type="file-pdf" />{{
                $t("action.imprimer")
              }}
            </a-button>
          </a-dropdown>
        </div>
      </div>
      <a-divider></a-divider>

      <div class="row">
        <div class="col-xl-8">
          <div class="card" v-if="selectedStudent && selectedClasse">
            <div class="data-table">
              <a-table
                @change="tableChanged"
                :loading="tableLoading"
                :pagination="false"
                :data-source="subjects"
                :columns="columns"
              >
                <template slot="mark" slot-scope="text, record">
                  <marks
                    :id="selectedStudent"
                    :subject="record._id"
                    :trimester="selectedTrimester"
                    :classroom="selectedClasse"
                    :studentMarks="studentMarks"
                    :ref="`markRefs${record._id}`"
                  />
                </template>

                <template slot="name" slot-scope="text">
                  <span class="arabic-font">{{ text }}</span>
                </template>

                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                    >{{ $t("paiement.chercher") }}</a-button
                  >
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleReset(clearFilters)"
                    >{{ $t("action.reinitialiser") }}</a-button
                  >
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>{{ text }}</template>
                </template>
              </a-table>
            </div>
          </div>

          <div class="row" v-if="selectedClasse && selectedStudent">
            <div class="col-xl-7">{{ $t("bulletin.toutMatieres") }}</div>
            <div class="col-xl-3">
              <a-button
                style="
                  margin-right: 5px;
                  margin-left: 20px;
                  margin-bottom: 10px;
                  background: #8bc34a !important;
                  border-color: #8bc34a !important;
                "
                type="primary"
                @click="saveAll()"
              >
                {{ $t("bulletin.enregistrerTout") }}<a-icon type="check" />
              </a-button>
            </div>
          </div>
        </div>
        <div class="col-xl-4" style="width: 100%">
          <div
            class="card"
            style="padding: 15px"
            v-if="classroomMarksStats && classroomMarksStats.classrooms"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="pr-3">
                <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
                  {{ $t("bulletin.classesActives") }}
                </h2>
                <p
                  class="font-size-15 mb-3"
                  v-if="classroomMarksStats.classrooms.length !== 0"
                >
                  {{ $t("bulletin.nombreClassesNonNote") }}
                </p>
                <p class="font-size-15 mb-3" v-else>
                  {{ $t("bulletin.tousClassesSontNotes") }}
                </p>
              </div>
              <div
                v-if="classroomMarksStats.classrooms.length !== 0"
                class="text-success font-weight-bold font-size-24"
              >
                {{ classroomMarksStats.classrooms.length }}
                {{ $t("bulletin.classes") }}
              </div>

              <div class="text-success font-weight-bold font-size-24" v-else>
                <a-icon type="check" />
              </div>
            </div>

            <div
              class="d-flex text-gray-5 justify-content-right font-size-14"
              v-if="classroomMarksStats.classrooms.length !== 0"
            >
              <a-button @click="visibleClassnameList = true">{{
                $t("action.afficher")
              }}</a-button>
            </div>
          </div>
          <div
            class="card"
            style="padding: 15px"
            v-if="selectedClasse && classroomMarksStats.students"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="pr-3">
                <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
                  {{ selectedClassName }}
                </h2>
                <p
                  class="font-size-15 mb-3"
                  v-if="
                    classroomMarksStats.students[selectedClasse] &&
                    classroomMarksStats.students[selectedClasse].length !== 0
                  "
                >
                  {{ $t("bulletin.nombreElevesNonNotes") }}
                </p>
                <p class="font-size-15 mb-3" v-else>
                  {{ $t("bulletin.tousElevesSontNotes") }}
                </p>
              </div>
              <div
                v-if="
                  classroomMarksStats.students[selectedClasse] &&
                  classroomMarksStats.students[selectedClasse].length !== 0
                "
                class="text-success font-weight-bold font-size-24"
              >
                {{ classroomMarksStats.students[selectedClasse].length }}
                {{ $t("menu.eleves") }}
              </div>
              <div class="text-success font-weight-bold font-size-24" v-else>
                <a-icon type="check" />
              </div>
            </div>

            <div
              class="d-flex text-gray-5 justify-content-right font-size-14"
              v-if="classroomMarksStats.students[selectedClasse]"
            >
              <a-button @click="visibleStudentList = true">{{
                $t("action.afficher")
              }}</a-button>
            </div>
          </div>

          <a-divider v-if="selectedStudent && selectedClasse">
            <span class="font-size-18">{{ selectedStudentName }}</span>
          </a-divider>

          <div class="card" v-if="selectedStudent && selectedClasse">
            <div class="card-body">
              <template v-if="!loadingBulletin">
                <div style="font-size: 1.75rem; color: black">
                  {{ $t("bulletin.moyenneTrimestre", { moyenne: moyenne }) }}
                </div>
                <div>
                  {{
                    $t("bulletin.moyenneMax", { moyenne: bulletin.maxMoyenne })
                  }}<br />
                  {{
                    $t("bulletin.moyenneMin", { moyenne: bulletin.minMoyenne })
                  }}
                </div>
              </template>
              <template v-else>
                <a-col :span="16" :offset="11">
                  <a-spin size="large" />
                </a-col>
              </template>
            </div>
          </div>

          <div class="card" v-if="selectedStudent && selectedClasse">
            <marksChart
              :data="studentMarks"
              :maxmin="marksMaxMin"
              :subjects="subjects"
            />
          </div>

          <div class="card" v-if="selectedStudent && selectedClasse">
            <moduleChart
              :data="studentMarks"
              :maxmin="marksMaxMin"
              :modules="listModules"
              :maxMinMoy="maxMinMoy"
            />
          </div>
        </div>
      </div>

      <a-modal
        v-model="visibleClassnameList"
        :title="$t('bulletin.listeClassesNonNote')"
        :width="500"
        :height="700"
        :key="'classnameListNotMarked'"
      >
        <div :style="{ overflowY: 'scroll' }">
          <div
            v-for="classname in classroomMarksStats.classrooms"
            :key="classname"
          >
            {{ classname }}
          </div>
        </div>
      </a-modal>

      <a-modal
        @ok="
          () => {
            visibleBulletinDate = false;
          }
        "
        @cancel="
          () => {
            visibleBulletinDate = false;
          }
        "
        v-model="visibleBulletinDate"
        :title="$t('bulletin.date')"
        :closable="false"
        :width="500"
        :height="700"
        :key="'bulletinDate'"
      >
        <div>
          <label style="margin-right: 5px">{{ $t("bulletin.date") }}</label>
          <a-date-picker
            :allowClear="false"
            @change="
              (val) => {
                bulletinDate = val;
              }
            "
            :default-value="moment(new Date())"
          />
        </div>
      </a-modal>

      <a-modal
        v-model="visibleStudentList"
        :title="$t('bulletin.listeEleveNonNote')"
        :width="500"
        :height="700"
        :key="'studentListNotMarked'"
      >
        <div
          :style="{ overflowY: 'scroll' }"
          v-if="selectedClasse && classroomMarksStats.students"
        >
          <div
            v-for="fullname in classroomMarksStats.students[selectedClasse]"
            :key="fullname"
          >
            {{ fullname }}
          </div>
        </div>
      </a-modal>
      <FinalBulletin
        :visibleFinalBulletin="visibleFinalBulletin"
        :studentID="selectedStudent"
        @closeFinalBulletin="closeFinalBulletin"
        @printLoading="setPrintLoading"
      />
      <AllFinalBulletin
        :visibleFinalBulletin="visibleAllFinalBulletin"
        :classroomID="selectedClasse"
        @closeFinalBulletin="closeAllFinalBulletin"
        @printLoading="setPrintLoading"
      />
    </div>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import marks from "./components/marks";
import marksChart from "./components/marksChart";
import moduleChart from "./components/moduleChart";
import FinalBulletin from "./components/FinalBulletin";
import AllFinalBulletin from "./components/AllFinalBulletin";

import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";

export default {
  computed: mapState(["settings", "user"]),
  components: {
    marks: marks,
    marksChart,
    moduleChart,
    FinalBulletin,
    AllFinalBulletin,
  },
  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletinSpec: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));

    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;
    this.schoolarYearNamePF = start + "-" + end; // PF = Pilote format
    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    apiClient
      .get("/mark/classroomMarksStats/" + this.selectedTrimester + "/specific")
      .then(({ data }) => {
        this.classroomMarksStats = data;
      });
  },
  data() {
    return {
      loadingBulletin: false,
      bulletinColors: {
        color1: [70, 187, 239],
        color2: [64, 187, 239],
        color3: [16, 169, 229],
        color4: [67, 62, 63],
        color5: [52, 172, 215],
        color6: [224, 237, 243],
        color7: [64, 187, 239],
        color8: [225, 225, 226],
      },
      schoolDetails: {},
      printLoading: false,
      visibleClassnameList: false,
      visibleStudentList: false,
      visibleBulletinDate: false,
      visibleFinalBulletin: false,
      visibleAllFinalBulletin: false,
      bulletinDate: new Date(),
      moyenne: "--",
      maxMoyenne: "--",
      minMoyenne: "--",
      bulletin: { maxMoyenne: "--", minMoyenne: "--", moyenne: "--" },
      schoolType: null,
      filtredTable: [],
      subjects: [],
      selectedStudent: this.$t("menu.liste"),
      selectedStudentName: "",
      studentLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      activeData: [],
      studentMarks: [],
      markRefs: [],
      marksMaxMin: [],
      maxMinMoy: {},
      classroomMarksStats: {},
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: this.$t("enseignant.matiere"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("bulletin.note"),
          dataIndex: "mark",
          scopedSlots: { customRender: "mark" },
        },
      ],
      listModules: [],
      selectedLevel: null,
      selectedClassName: "",
    };
  },
  methods: {
    async openFinalBulletin() {
      this.$gtag.event("Imp allBulletinAnnuel", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.visibleFinalBulletin = true;
    },
    async openAllFinalBulletin() {
      this.$gtag.event("Imp BulletinAnnuel", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.visibleAllFinalBulletin = true;
    },
    closeFinalBulletin() {
      this.visibleFinalBulletin = false;
    },
    closeAllFinalBulletin() {
      this.visibleAllFinalBulletin = false;
    },
    setPrintLoading(val) {
      this.printLoading = val;
    },
    moment,
    async sleep(timeout) {
      await new Promise((r) => setTimeout(r, timeout));
    },
    async chooseBulletinDate() {
      this.visibleBulletinDate = true;
      return new Promise(async (res) => {
        while (this.visibleBulletinDate) {
          await this.sleep(500);
        }
        res();
      });
    },
    confirmeBulletinValidation() {
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    isValidatedBulletin() {
      if (this.selectedClasse) {
        const bulletin = this.classes.find(
          (item) => item._id === this.selectedClasse
        ).bulletinSpec || { sem1: false, sem2: false, sem3: false };
        if (this.selectedTrimester == 1 && bulletin.sem1 == true) return true;
        else if (this.selectedTrimester == 2 && bulletin.sem2 == true)
          return true;
        else if (this.selectedTrimester == 3 && bulletin.sem3 == true)
          return true;
      }
      return false;
    },
    validateBulletin() {
      const isValidated = this.isValidatedBulletin();
      const bulletin = this.classes.find(
        (item) => item._id === this.selectedClasse
      ).bulletinSpec || { sem1: false, sem2: false, sem3: false };
      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: {
            bulletinSpec: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            avaliable: !isValidated,
            type: "specific",
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse) item.bulletinSpec = bulletin;
            return item;
          });

          if (!isValidated)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
        });
    },
    saveAll() {
      let thereEmpty = false;
      this.subjects.forEach((s) => {
        const el = this.$refs[`markRefs${s._id}`];
        if (el && el.edit && !el.newMark) {
          thereEmpty = true;
          return this.$message.error(this.$t("error.impoEnregistrerVide"));
        }
      });

      if (!thereEmpty) {
        this.subjects.forEach((s) => {
          const el = this.$refs[`markRefs${s._id}`];
          if (el && !el.mark && el.newMark) {
            el.addMark(false);
          }
          if (el && el.edit)
            if (el.newMark) {
              el.updateMark(false);
            }
        });

        return this.$message.success(this.$t("success.noteToutesEnregistre"));
      }
    },
    async updateStudentMarks() {
      this.tableLoading = true;
      await apiClient
        .post("/mark/filter", {
          query: {
            student: this.selectedStudent,
            trimester: this.selectedTrimester,
          },
        })
        .then(({ data }) => {
          this.studentMarks = data;
        });

      this.updateBulletin();
    },

    async updateBulletin() {
      this.loadingBulletin = true;
      await apiClient
        .get(
          "/specificBulletin/" +
            this.selectedStudent +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          this.bulletin = data;
          this.moyenne = this.bulletin.moyenne;
          this.loadingBulletin = false;
        })
        .finally(() => (this.tableLoading = false));
    },

    async getModules(level) {
      await apiClient
        .post("/moduleSpecific/filter", {
          query: {
            status: "active",
            level: level,
            trimester: this.selectedTrimester,
          },
        })
        .then((res) => {
          this.listModules = res.data;

          this.listModules.forEach((m) => this.subjects.push(...m.subjects));
          if (res.data.length == 0)
            this.$message.warning(this.$t("error.aucModule"));
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        });
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async handleClassChange(on, value) {
      this.subjects = [];
      this.markRefs = [];
      this.selectedStudent = null;
      this.selectedStudentName = "";
      this.studentLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                firstNameLower: { $toLower: "$firstName" },

                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
          this.studentLoading = false;
        });

      apiClient
        .get(
          "/mark/maxmin/" + this.selectedClasse + "/" + this.selectedTrimester
        )
        .then((res) => (this.marksMaxMin = res.data));

      apiClient
        .get(
          "/mark/moymaxmin/" +
            this.selectedClasse +
            "/" +
            this.selectedLevel +
            "/" +
            this.selectedTrimester +
            "/specific"
        )
        .then((res) => (this.maxMinMoy = res.data));

      //get modules
      this.getModules(this.selectedLevel);
    },

    roundRect(ctx, x, y, width, height, radius, fill, stroke) {
      const cornerRadius = {
        upperLeft: 0,
        upperRight: 0,
        lowerLeft: 0,
        lowerRight: 0,
      };
      if (typeof stroke == "undefined") {
        stroke = true;
      }
      if (typeof radius === "object") {
        for (const side in radius) {
          cornerRadius[side] = radius[side];
        }
      }

      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgb(70, 187, 239)";

      ctx.beginPath();
      ctx.moveTo(x + cornerRadius.upperLeft, y);
      ctx.lineTo(x + width - cornerRadius.upperRight, y);
      ctx.quadraticCurveTo(
        x + width,
        y,
        x + width,
        y + cornerRadius.upperRight
      );
      ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - cornerRadius.lowerRight,
        y + height
      );
      ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
      ctx.quadraticCurveTo(
        x,
        y + height,
        x,
        y + height - cornerRadius.lowerLeft
      );
      ctx.lineTo(x, y + cornerRadius.upperLeft);
      ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
      ctx.closePath();
      if (stroke) {
        ctx.stroke();
      }
      if (fill) {
        ctx.fill();
      }
    },

    async printAllBulletins() {
      this.$gtag.event("Imp All bulletin pilote", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;
      const doc = new JsPDF();
      const ctx = doc.context2d;
      let bulletinData;
      let firstPage = true;
      await apiClient
        .get(
          "/specificBulletin/all/" +
            this.selectedClasse +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          bulletinData = data;
        });

      let affichageBulletin;

      await apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: "specific",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            affichageBulletin = res.data[0];
          } else {
            affichageBulletin = {
              specific: true,
              pedagogic: false,
              personalized: false,
              specificName: "معدل",
            };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        });

      bulletinData.students.forEach((student) => {
        if (!firstPage) {
          doc.addPage();
        }
        this.drawBulletin(doc, ctx, student, bulletinData, affichageBulletin);
        firstPage = false;
      });

      this.printLoading = false;
      const missingStudent = this.classroomMarksStats.students
        ? this.classroomMarksStats.students[this.selectedClasse]
        : [];
      const className = this.selectedClassName;
      if (missingStudent && missingStudent.length !== 0)
        Modal.confirm({
          title: this.$t("bulletin.confirmPrintEleves", {
            count: missingStudent.length,
          }),
          content: (h) => {
            let list = [];
            missingStudent.forEach((s) => {
              list.push(h("li", s));
            });
            return h("ul", list);
          },
          okText: this.$t("bulletin.generateAnyway"),
          okType: "danger",
          cancelText: this.$t("action.annuler"),
          onOk() {
            doc.save(`Bulletin ${className}.pdf`);
          },
          onCancel() {},
        });
      else doc.save(`Bulletin pilote ${className}.pdf`);
    },

    async printPVBulletin() {
      this.$gtag.event("Imp PV BulletinPilote", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;

      const doc = new JsPDF({
        orientation: "l",
        unit: "mm",
        format: "a3",
      });
      let bulletinData;

      await apiClient
        .get(
          "/specificBulletin/all/" +
            this.selectedClasse +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          bulletinData = data;
        });

      const cellToDelete = {};
      const head = [];
      const modulesHead = [];

      const defaultMax = [cellToDelete];
      const defaultMin = [cellToDelete];
      defaultMax.unshift(
        { content: "اعلى عدد بالقسم", colSpan: 2 },
        cellToDelete
      );
      defaultMin.unshift(
        { content: "ادنى عدد بالقسم", colSpan: 2 },
        cellToDelete
      );

      this.listModules.forEach((m) => {
        m.subjects.forEach((s) => {
          head.unshift(s.name);
          defaultMax.unshift({ content: "--" });
          defaultMin.unshift({ content: "--" });
        });
        head.unshift("المجموع");
        head.unshift("معدل المجال");
        defaultMax.unshift("", "");
        defaultMin.unshift("", "");

        modulesHead.unshift({
          content: m.name,
          colSpan: m.subjects.length + 2,
        });
        for (let i = 0; i < m.subjects.length + 1; i++)
          modulesHead.unshift(cellToDelete);
      });
      head.unshift("مجموع المجالات");
      head.unshift("الرتبة");
      head.unshift("المعدل");
      defaultMax.unshift("--", "", "");
      defaultMin.unshift("--", "", "");

      modulesHead.unshift({ content: "النتيجة", colSpan: 3 });
      for (let i = 0; i < 2; i++) modulesHead.unshift(cellToDelete);

      const sMax = [cellToDelete];
      const sMin = [cellToDelete];
      sMax.unshift({ content: "اعلى عدد بالقسم", colSpan: 2 }, cellToDelete);
      sMin.unshift({ content: "ادنى عدد بالقسم", colSpan: 2 }, cellToDelete);

      const data = [];
      let studentID = 1;
      bulletinData.students.forEach((student) => {
        const studentData = [];
        studentData.unshift(studentID++);
        studentData.unshift(student.fullName);
        let mSum = 0;
        student.modules.forEach((m) => {
          let sum = 0;
          m.subjects.forEach((s) => {
            studentData.unshift(
              s.mark != "--" ? Number(s.mark).toFixed(2) : "--"
            );
            if (s.mark != "--") sum += s.mark;

            if (studentID == 2) {
              sMax.unshift({
                content: s.max != "--" ? s.max.toFixed(2) : "--",
              });
              sMin.unshift({
                content: s.min != "--" ? s.min.toFixed(2) : "--",
              });
            }
          });
          studentData.unshift(sum.toFixed(2));
          studentData.unshift(m.moyenne);
          if (studentID == 2) {
            sMax.unshift("", "");
            sMin.unshift("", "");
          }
          if (m.moyenne != "--") mSum += Number(m.moyenne);
        });
        studentData.unshift(mSum.toFixed(2));
        studentData.unshift(student.rank);
        studentData.unshift(student.moyenne);

        data.push(studentData);
      });

      sMax.unshift(bulletinData.maxMoyenne, "", "");
      sMin.unshift(bulletinData.minMoyenne, "", "");

      if (bulletinData.students.length === 0) {
        data.push(defaultMax);
        data.push(defaultMin);
      } else {
        data.push(sMax);
        data.push(sMin);
      }

      const array = [
        [...modulesHead, { content: "", colSpan: 2 }, cellToDelete],
        [
          ...Array(head.length).fill(""),
          { content: bulletinData.classroomName, colSpan: 2 },
          cellToDelete,
        ],
        ...data,
      ];

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      const className = this.selectedClassName;

      doc.autoTable({
        startY: 5,
        body: array,
        theme: "striped",
        styles: {},
        bodyStyles: {
          lineWidth: 0.2,
          lineColor: [73, 138, 159],
          halign: "center",
          valign: "center",
          fontSize: 7,
          cellPadding: 1.2,
        },
        columnStyles: {
          0: {
            columnWidth: 20,
          },
        },

        didDrawCell: function (data) {
          const length = data.row.raw.length;
          if (data.row.index === 1 && data.column.index < length - 1) {
            const width = doc.getTextWidth(head[data.column.index]);
            doc.text(
              head[data.column.index],
              data.cell.x + data.cell.width / 2 - 0.3,
              data.cell.y + 20 + width / 2,
              { angle: 90 }
            );
          }
        },

        willDrawCell: function (data) {
          if (data.row.index !== 1) {
            data.cell.y -= 1.8;
          }
        },

        didParseCell: function (data) {
          const length = data.row.raw.length;

          if (data.column.index === length - 1) data.cell.styles.fontSize = 11;

          if (data.row.index == 1) {
            data.cell.styles.minCellHeight = 36;
            data.cell.styles.minCellWidth = 5;
            data.cell.styles.halign = "center";
            data.cell.styles.valign = "center";

            if (data.column.index !== length - 1) data.cell.styles.fontSize = 8;
            else data.cell.styles.fontSize = 11;

            if (
              [
                "معدل المجال",
                "المعدل",
                "المجموع",
                "مجموع المجالات",
                "الرتبة",
              ].includes(head[data.column.index])
            ) {
              data.cell.styles.fontStyle = "Bold";
              data.cell.styles.fontSize = 8.5;
            } else data.cell.styles.fontStyle = "normal";

            if ("معدل المجال" == head[data.column.index])
              data.cell.styles.fillColor = "#8bc34a";
            else if ("المعدل" == head[data.column.index])
              data.cell.styles.fillColor = "#ffca62";
          } else {
            if (data.column.index == length - 1) data.cell.styles.fontSize = 8;
          }

          if (data.row.index == 0) {
            data.cell.styles.fontSize = 8;
            data.cell.styles.fillColor = "#8bc34a";
          }

          if (/[a-zA-Z]/.test(data.cell.text[0]))
            data.cell.styles.font = "helvetica";
          else if (
            data.row.index !== 1 &&
            (!isNaN(Number(data.cell.text[0])) || data.cell.text[0] == "--")
          ) {
            data.cell.styles.font = "helvetica";
            data.cell.styles.fontStyle = "bold";
          } else data.cell.styles.font = "Amiri";

          if (data.row.index == array.length - 2) {
            if (data.column.index !== length - 1)
              data.cell.styles.textColor = "#8bc34a";
            else data.cell.styles.fillColor = "#8bc34a";
            if (data.cell.styles.font == "Amiri")
              data.cell.styles.fontStyle = "Bold";
          }

          if (data.row.index == array.length - 1) {
            if (data.column.index !== length - 1)
              data.cell.styles.textColor = "#f44336";
            else data.cell.styles.fillColor = "#ffaea8";
            if (data.cell.styles.font == "Amiri")
              data.cell.styles.fontStyle = "Bold";
          }
        },
      });

      this.printLoading = false;

      const missingStudent = this.classroomMarksStats.students
        ? this.classroomMarksStats.students[this.selectedClasse]
        : [];

      if (missingStudent && missingStudent.length !== 0)
        Modal.confirm({
          title: this.$t("bulletin.confirmPrintEleves", {
            count: missingStudent.length,
          }),
          content: (h) => {
            let list = [];
            missingStudent.forEach((s) => {
              list.push(h("li", s));
            });
            return h("ul", list);
          },
          okText: this.$t("bulletin.generateAnyway"),
          okType: "danger",
          cancelText: this.$t("action.annuler"),
          onOk() {
            doc.save(`Bulletin ${className}.pdf`);
          },
          onCancel() {},
        });
      else doc.save(`PV Bulletin pilote ${className}.pdf`);
    },

    async printPVBulletinLevel() {
      this.$gtag.event("Imp PV BulletinPilote by level", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;

      const doc = new JsPDF({
        orientation: "l",
        unit: "mm",
        format: "a3",
      });
      let bulletinData;

      await apiClient
        .get(
          "/specificBulletin/pv/" +
            this.selectedLevel +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          bulletinData = data;
        });

      const cellToDelete = {};
      const head = [];
      const modulesHead = [];

      const defaultMax = [cellToDelete];
      const defaultMin = [cellToDelete];
      defaultMax.unshift(
        { content: "اعلى عدد بالمستوى", colSpan: 2 },
        cellToDelete
      );
      defaultMin.unshift(
        { content: "ادنى عدد بالمستوى", colSpan: 2 },
        cellToDelete
      );

      this.listModules.forEach((m) => {
        m.subjects.forEach((s) => {
          head.unshift(s.name);
          defaultMax.unshift({ content: "--" });
          defaultMin.unshift({ content: "--" });
        });
        head.unshift("المجموع");
        head.unshift("معدل المجال");
        defaultMax.unshift("", "");
        defaultMin.unshift("", "");

        modulesHead.unshift({
          content: m.name,
          colSpan: m.subjects.length + 2,
        });
        for (let i = 0; i < m.subjects.length + 1; i++)
          modulesHead.unshift(cellToDelete);
      });
      head.unshift("مجموع المجالات");
      head.unshift("الرتبة");
      head.unshift("المعدل");
      defaultMax.unshift("--", "", "");
      defaultMin.unshift("--", "", "");

      modulesHead.unshift({ content: "النتيجة", colSpan: 3 });
      for (let i = 0; i < 2; i++) modulesHead.unshift(cellToDelete);

      const sMax = [cellToDelete];
      const sMin = [cellToDelete];
      sMax.unshift({ content: "اعلى عدد بالمستوى", colSpan: 2 }, cellToDelete);
      sMin.unshift({ content: "ادنى عدد بالمستوى", colSpan: 2 }, cellToDelete);

      const data = [];
      let studentID = 1;
      bulletinData.students.forEach((student) => {
        const studentData = [];
        studentData.unshift(student.fullName);
        studentData.unshift(student.classroomName);
        let mSum = 0;
        studentID++;
        student.modules.forEach((m) => {
          let sum = 0;
          m.subjects.forEach((s) => {
            studentData.unshift(s.mark != "--" ? s.mark.toFixed(2) : s.mark);
            if (s.mark != "--") sum += s.mark;

            if (studentID == 2) {
              sMax.unshift({
                content: s.max != "--" ? s.max.toFixed(2) : s.max,
              });
              sMin.unshift({
                content: s.min != "--" ? s.min.toFixed(2) : s.min,
              });
            }
          });
          studentData.unshift(sum.toFixed(2));
          studentData.unshift(m.moyenne);
          if (studentID == 2) {
            sMax.unshift("", "");
            sMin.unshift("", "");
          }
          if (m.moyenne != "--") mSum += Number(m.moyenne);
        });
        studentData.unshift(mSum.toFixed(2));
        studentData.unshift(student.rank);
        studentData.unshift(student.moyenne);
        data.push(studentData);
      });

      sMax.unshift(bulletinData.maxMoyenne, "", "");
      sMin.unshift(bulletinData.minMoyenne, "", "");

      if (bulletinData.students.length === 0) {
        data.push(defaultMax);
        data.push(defaultMin);
      } else {
        data.push(sMax);
        data.push(sMin);
      }

      const array = [
        [...modulesHead, { content: "", colSpan: 2 }, cellToDelete],
        [
          ...Array(head.length).fill(""),
          { content: this.selectedLevel + " المستوى", colSpan: 2 },
          cellToDelete,
        ],
        ...data,
      ];

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      const className = this.selectedClassName;

      doc.autoTable({
        startY: 5,
        body: array,
        theme: "striped",
        styles: {},
        bodyStyles: {
          lineWidth: 0.2,
          lineColor: [73, 138, 159],
          halign: "center",
          valign: "center",
          fontSize: 7,
          cellPadding: 1.2,
        },
        columnStyles: {},

        didDrawCell: function (data) {
          const length = data.row.raw.length;
          if (data.row.index === 1 && data.column.index < length - 1) {
            const width = doc.getTextWidth(head[data.column.index]);
            doc.text(
              head[data.column.index],
              data.cell.x + data.cell.width / 2 - 0.3,
              data.cell.y + 20 + width / 2,
              { angle: 90 }
            );
          }
        },

        willDrawCell: function (data) {
          if (data.row.index !== 1) {
            data.cell.y -= 1.8;
          }
        },

        didParseCell: function (data) {
          const length = data.row.raw.length;

          if (data.column.index === length - 1) data.cell.styles.fontSize = 11;

          if (data.row.index == 1) {
            data.cell.styles.minCellHeight = 36;
            data.cell.styles.minCellWidth = 5;
            data.cell.styles.halign = "center";
            data.cell.styles.valign = "center";

            if (data.column.index !== length - 1) data.cell.styles.fontSize = 8;
            else data.cell.styles.fontSize = 11;

            if (
              [
                "معدل المجال",
                "المعدل",
                "المجموع",
                "مجموع المجالات",
                "الرتبة",
              ].includes(head[data.column.index])
            ) {
              data.cell.styles.fontStyle = "Bold";
              data.cell.styles.fontSize = 8.5;
            } else data.cell.styles.fontStyle = "normal";

            if ("معدل المجال" == head[data.column.index])
              data.cell.styles.fillColor = "#8bc34a";
            else if ("المعدل" == head[data.column.index])
              data.cell.styles.fillColor = "#ffca62";
          } else {
            if (data.column.index == length - 1) data.cell.styles.fontSize = 8;
          }

          if (data.row.index == 0) {
            data.cell.styles.fontSize = 8;
            data.cell.styles.fillColor = "#ace36d";
          }

          if (/[a-zA-Z]/.test(data.cell.text[0]))
            data.cell.styles.font = "helvetica";
          else if (
            data.row.index !== 1 &&
            (!isNaN(Number(data.cell.text[0])) || data.cell.text[0] == "--")
          ) {
            data.cell.styles.font = "helvetica";
            data.cell.styles.fontStyle = "bold";
          } else data.cell.styles.font = "Amiri";

          if (data.row.index == array.length - 2) {
            if (data.column.index !== length - 1)
              data.cell.styles.textColor = "#8bc34a";
            else data.cell.styles.fillColor = "#8bc34a";
            if (data.cell.styles.font == "Amiri")
              data.cell.styles.fontStyle = "Bold";
          }

          if (data.row.index == array.length - 1) {
            if (data.column.index !== length - 1)
              data.cell.styles.textColor = "#f44336";
            else data.cell.styles.fillColor = "#ffaea8";
            if (data.cell.styles.font == "Amiri")
              data.cell.styles.fontStyle = "Bold";
          }
        },
      });

      this.printLoading = false;

      doc.save(`PV Bulletin pilote par niveau ${this.selectedLevel}.pdf`);
    },

    async printPVAnnuel() {
      this.$gtag.event("Imp PV BulletinPilote Annuel", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;
      const cellToDelete = {};

      const doc = new JsPDF({
        orientation: "m",
        unit: "mm",
        format: "a3",
      });

      doc.setFont("Amiri");

      const pdf_width = doc.internal.pageSize.getWidth();

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

      const className = this.selectedClassName;

      doc.text("PV Annuel Pilote - " + className, pdf_width / 2, 20, {
        align: "center",
      });

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.setFontSize(9);

      doc.text(
        new Date().toLocaleDateString("fr-FR", options),
        pdf_width - 17,
        8,
        {
          align: "right",
        }
      );

      let bulletinData;

      await apiClient
        .get("/specificBulletin/final/all/" + this.selectedClasse)
        .then((res) => (bulletinData = res.data));

      let array = [
        [
          { content: "الرتبة", styles: { fontStyle: "Bold" } },
          { content: "معدل العام", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
          { content: "الأسم", styles: { fontStyle: "Bold" } },
          { content: "#", styles: { fontStyle: "Bold" } },
        ],
      ];

      let id = 1;
      bulletinData.students.forEach((student) => {
        const studentData = [];
        studentData.push(student.rank);
        studentData.push(student.finalMoyenne);
        studentData.push(student.moyenneThree);
        studentData.push(student.moyenneTwo);
        studentData.push(student.moyenneOne);
        studentData.push(student.fullName);
        studentData.push(id++);
        array.push(studentData);
      });

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.autoTable({
        startY: 35,
        body: array,
        theme: "striped",
        styles: {},
        bodyStyles: {
          lineWidth: 0.2,
          lineColor: [73, 138, 159],
          halign: "center",
          fontSize: 10,
          font: "Amiri",
          // cellPadding: 1.2,
        },
        columnStyles: {
          0: { cellWidth: 20 },
        },
        rowPageBreak: "avoid",
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(9); //Optional

      doc.setTextColor(0, 0, 0);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      //end page numbering

      this.printLoading = false;

      doc.save(`PV Annuel Pilote ${className}.pdf`);
    },

    async printPVAnnuelByLevel() {
      this.$gtag.event("Imp PV Annuel by level", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;
      const cellToDelete = {};

      const doc = new JsPDF({
        orientation: "m",
        unit: "mm",
        format: "a3",
      });

      doc.setFont("Amiri");

      const pdf_width = doc.internal.pageSize.getWidth();

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

      const levels = {
        "-2": "Préscolaire",
        "-1": "تحضيري",
        1: "سنة أولى",
        2: "سنة ثانية",
        3: "سنة ثالثة",
        4: "سنة رابعة",
        5: "سنة خامسة",
        6: "سنة سادسة",
        7: "سنة سابعة",
        8: "سنة ثامنة",
        9: "سنة تاسعة",
        10: "أولى ثانوي",
        11: "ثانية ثانوي",
        12: "ثالثة ثانوي",
        13: "رابعة ثاتوي",
      };

      doc.text(
        "PV Annuel Pilote - " + levels[this.selectedLevel],
        pdf_width / 2,
        20,
        {
          align: "center",
        }
      );

      doc.setFontSize(9);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.text(
        new Date().toLocaleDateString("fr-FR", options),
        pdf_width - 17,
        8,
        {
          align: "right",
        }
      );

      let bulletinData;

      await apiClient
        .get("/specificBulletin/pv/final/" + this.selectedLevel)
        .then((res) => (bulletinData = res.data));

      let array = [
        [
          { content: "الرتبة", styles: { fontStyle: "Bold" } },
          { content: "معدل العام", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
          { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
          { content: "القسم", styles: { fontStyle: "Bold" } },
          { content: "الأسم", styles: { fontStyle: "Bold" } },
        ],
      ];

      bulletinData.students.forEach((student) => {
        const studentData = [];
        studentData.push(student.rank);
        studentData.push(student.finalMoyenne);
        studentData.push(student.moyenneThree);
        studentData.push(student.moyenneTwo);
        studentData.push(student.moyenneOne);
        studentData.push(student.classroomName);
        studentData.push(student.fullName);
        array.push(studentData);
      });

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.autoTable({
        startY: 35,
        body: array,
        theme: "striped",
        styles: {},
        bodyStyles: {
          lineWidth: 0.2,
          lineColor: [73, 138, 159],
          halign: "center",
          fontSize: 9,
          font: "Amiri",
          // cellPadding: 1.2,
        },
        columnStyles: {
          0: { cellWidth: 20 },
        },
        rowPageBreak: "avoid",
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(9); //Optional

      doc.setTextColor(0, 0, 0);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      //end page numbering

      this.printLoading = false;

      doc.save(`PV Annuel Pilote ${levels[this.selectedLevel]}.pdf`);
    },

    async drawBulletin(doc, ctx, student, bulletinData, affichageBulletin) {
      const record = student;

      const pdf_width = doc.internal.pageSize.width;
      let width = 0;

      let arrayOfImages = this.settings.image;
      let imgData;
      let buildingName = "";
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
          buildingName = arrayOfImages[i].name;
        }
      }

      // logo
      doc.addImage(imgData, "JPEG", pdf_width - 30, 5, 20, 20);

      doc.setFont("Amiri", "normal");

      doc.setTextColor(99, 87, 91);
      doc.setFontSize(12);
      let text = "École : " + buildingName;
      doc.text(text, 15, 12);
      text = "Année scolaire : " + this.schoolarYearNamePF;
      doc.text(text, 15, 20);

      const className = bulletinData.classroomName;

      // student classroom
      text = "Classe : " + className;
      doc.text(text, 110, 12);

      // student name
      text = "Élève : " + record.fullName;
      doc.text(text, 110, 20);

      // trimester text
      doc.setFontSize(20);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(67, 62, 63);
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "Relevé des notes - 1er trimestre";
          break;
        case "2":
          title = "Relevé des notes - 2ème trimestre";
          break;
        case "3":
          title = "Relevé des notes - 3ème trimestre";
          break;
      }
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 37);

      const cellToDelete = {};
      let moyTrimester = 0;
      let sumCoef = 0;
      let topMargin = -15;

      const affichageMoy = affichageBulletin.moyModule;

      student.modules.forEach((m) => {
        let arr = [];
        let array = [];

        m.subjects.forEach((c) => {
          arr[0] = {
            content: c.name,
            styles: {
              halign: "center",
              valign: "middle",
              fillColor: "#55a1f1",
              fontSize: 10,
              textColor: "#ffffff",
            },
          };

          arr[1] = {
            content: c.coef,
            styles: {
              halign: "center",
              valign: "middle",
              fillColor: "#55a1f1",
              fontSize: 10,
              textColor: "#ffffff",
            },
          };

          if (c.mark != "--") {
            arr[2] = {
              content: c.mark,
              styles: {
                halign: "center",
                fontSize: 10,
                valign: "middle",
              },
            };
          } else {
            arr[2] = {
              content: "--",
              styles: {
                fontSize: 10,
                halign: "center",
                valign: "middle",
              },
            };
          }

          //best mark
          arr[3] = {
            content: c.max,
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 10,
            },
          };

          //lowest mark
          arr[4] = {
            content: c.min,
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 10,
            },
          };

          arr[5] = cellToDelete;
          array.push(arr);
          arr = [];
        });
        if (affichageMoy)
          //end shifting
          array[0][5] = {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            content: m.moyenne,
            rowSpan: array.length,
          };
        else {
          //affichage appréciation
          array[0][5] = {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            content: m.appreciation.trim(),
            rowSpan: array.length,
          };
        }
        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }

        doc.autoTable({
          startY: 60 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            valign: "middle",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          headStyles: {
            fillColor: "#3e93ef",
            fontSize: 10,
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
          },
          head: [
            [
              {
                content: m.name,
                colSpan: 6,
                styles: {
                  halign: "center",
                  fillColor: "#187fec",
                  lineColor: [255, 255, 255],
                  fontSize: 15,
                  textColor: "#ffffff",
                  font: /[a-zA-Z]/.test(m.name) ? "Amiri" : "Noto Sans Arabic",
                  fontStyle: "Bold",
                },
              },
            ],
            affichageMoy
              ? [
                  "Matière",
                  "Coef",
                  "Note",
                  "Note la plus haute",
                  "Note la plus basse",
                  "Moyenne de module",
                ]
              : [
                  "Matière",
                  "Coef",
                  "Note",
                  "Note la plus haute",
                  "Note la plus basse",
                  "Appréciation",
                ],
          ],
          body: array,
          margin: {},
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },
          columnStyles: affichageMoy
            ? {
                0: { cellWidth: 58 },
                1: { cellWidth: 14 },
                2: { cellWidth: 15 },
                3: { cellWidth: 30 },
                4: { cellWidth: 30 },
                5: { cellWidth: 34 },
              }
            : {
                0: { cellWidth: 60 },
                1: { cellWidth: 12 },
                2: { cellWidth: 14 },
                3: { cellWidth: 25 },
                4: { cellWidth: 25 },
                5: { cellWidth: 46 },
              },
        });

        topMargin = doc.lastAutoTable.finalY - 55;
      });

      if (affichageBulletin.specific) {
        doc.autoTable({
          startY: 60 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            valign: "middle",
            fontStyle: "Bold",
            fontSize: 14,
          },
          cellPadding: 0,
          headStyles: {
            fontSize: 11,
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
          },
          head: [
            [
              affichageBulletin.specificName,
              "Moyenne la plus haute",
              "Moyenne la plus basse",
            ],
          ],
          body: [
            [student.moyenne, bulletinData.maxMoyenne, bulletinData.minMoyenne],
          ],
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },

          columnStyles: {
            0: { cellWidth: 61 },
            1: { cellWidth: 61 },
            2: { cellWidth: 61 },
          },
        });

        topMargin = doc.lastAutoTable.finalY - 55;
      }

      if (affichageBulletin.pedagogic || affichageBulletin.personalized) {
        let perMoyenne = "--";
        if (
          affichageBulletin.personalized &&
          student.pedaMoyenne != "--" &&
          student.moyenne != "--"
        ) {
          const totalCoef =
            affichageBulletin.pedagogicCoef + affichageBulletin.specificCoef;
          perMoyenne = String(
            (
              (Number(student.moyenne) * affichageBulletin.specificCoef +
                Number(student.pedaMoyenne) * affichageBulletin.pedagogicCoef) /
              totalCoef
            ).toFixed(2)
          );
        }
        const head = [];
        const body = [];
        let tableWidth = 0;
        if (affichageBulletin.pedagogic) {
          head.push(affichageBulletin.pedagogicName);
          body.push(student.pedaMoyenne);
          tableWidth += 61;
        }

        if (affichageBulletin.personalized) {
          head.push(affichageBulletin.personalizedName);
          body.push(perMoyenne);
          tableWidth += 61;
        }

        let margin = (pdf_width - tableWidth) / 2;

        doc.autoTable({
          startY: 60 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            valign: "middle",
            fontStyle: "Bold",
            fontSize: 14,
          },
          cellPadding: 0,
          headStyles: {
            fontSize: 11,
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
          },
          head: [head],
          body: [body],
          margin: { left: margin, right: margin },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },

          columnStyles: {
            0: { cellWidth: 61 },
            1: { cellWidth: 61 },
          },
        });

        topMargin = doc.lastAutoTable.finalY - 55;
      }
    },

    //generate bulletin pdf
    async convert() {
      this.$gtag.event("Imp bulletin pilote", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;

      const record = this.activeData.find((s) => s._id == this.selectedStudent);
      let missingMarks = [];

      await this.updateBulletin();

      let affichageBulletin;

      await apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: "specific",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            affichageBulletin = res.data[0];
          } else {
            affichageBulletin = {
              specific: true,
              pedagogic: false,
              personalized: false,
              moyModule: true,
              specificName: "معدل",
            };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        });

      let topMargin = -15;

      const doc = new JsPDF();
      const pdf_width = doc.internal.pageSize.width;
      let width = 0;

      let arrayOfImages = this.settings.image;
      let imgData;
      let buildingName = "";
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
          buildingName = arrayOfImages[i].name;
        }
      }

      // logo
      doc.addImage(imgData, "JPEG", pdf_width - 30, 5, 20, 20);

      doc.setFont("Amiri", "normal");

      doc.setTextColor(99, 87, 91);
      doc.setFontSize(12);
      let text = "École : " + buildingName;
      doc.text(text, 15, 12);
      text = "Année scolaire : " + this.schoolarYearNamePF;
      doc.text(text, 15, 20);

      const className = this.bulletin.classroomName;

      // student classroom
      text = "Classe : " + className;
      doc.text(text, 110, 12);

      // student name
      text = "Élève : " + this.bulletin.fullName;
      doc.text(text, 110, 20);

      // trimester text
      doc.setFontSize(20);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(67, 62, 63);
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "Relevé des notes - 1er trimestre";
          break;
        case "2":
          title = "Relevé des notes - 2ème trimestre";
          break;
        case "3":
          title = "Relevé des notes - 3ème trimestre";
          break;
      }
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 37);

      const cellToDelete = {};
      const affichageMoy = affichageBulletin.moyModule;

      this.bulletin.modules.forEach((m) => {
        let arr = [];
        let array = [];

        m.subjects.forEach((c) => {
          arr[0] = {
            content: c.name,
            styles: {
              halign: "center",
              valign: "middle",
              fillColor: "#55a1f1",
              fontSize: 10,
              textColor: "#ffffff",
            },
          };

          arr[1] = {
            content: c.coef,
            styles: {
              halign: "center",
              valign: "middle",
              fillColor: "#55a1f1",
              fontSize: 10,
              textColor: "#ffffff",
            },
          };

          if (c.mark != "--") {
            arr[2] = {
              content: c.mark,
              styles: {
                halign: "center",
                fontSize: 10,
                valign: "middle",
              },
            };
          } else {
            arr[2] = {
              content: "--",
              styles: {
                fontSize: 10,
                halign: "center",
                valign: "middle",
              },
            };
            missingMarks.push(c.name);
          }

          //best mark
          arr[3] = {
            content: c.max,
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 10,
            },
          };

          //lowest mark
          arr[4] = {
            content: c.min,
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 10,
            },
          };

          arr[5] = cellToDelete;
          array.push(arr);
          arr = [];
        });
        if (affichageMoy)
          //end shifting
          array[0][5] = {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            content: m.moyenne,
            rowSpan: array.length,
          };
        else {
          //affichage appréciation
          array[0][5] = {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            content: m.appreciation.trim(),
            rowSpan: array.length,
          };
        }
        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }

        doc.autoTable({
          startY: 60 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            valign: "middle",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          headStyles: {
            fillColor: "#3e93ef",
            fontSize: 10,
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
          },
          head: [
            [
              {
                content: m.name,
                colSpan: 6,
                styles: {
                  halign: "center",
                  fillColor: "#187fec",
                  lineColor: [255, 255, 255],
                  fontSize: 15,
                  textColor: "#ffffff",
                  font: /[a-zA-Z]/.test(m.name) ? "Amiri" : "Noto Sans Arabic",
                  fontStyle: "Bold",
                },
              },
            ],
            affichageMoy
              ? [
                  "Matière",
                  "Coef",
                  "Note",
                  "Note la plus haute",
                  "Note la plus basse",
                  "Moyenne de module",
                ]
              : [
                  "Matière",
                  "Coef",
                  "Note",
                  "Note la plus haute",
                  "Note la plus basse",
                  "Appréciation",
                ],
          ],
          body: array,
          margin: {},
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },
          columnStyles: affichageMoy
            ? {
                0: { cellWidth: 58 },
                1: { cellWidth: 14 },
                2: { cellWidth: 15 },
                3: { cellWidth: 30 },
                4: { cellWidth: 30 },
                5: { cellWidth: 34 },
              }
            : {
                0: { cellWidth: 60 },
                1: { cellWidth: 12 },
                2: { cellWidth: 14 },
                3: { cellWidth: 25 },
                4: { cellWidth: 25 },
                5: { cellWidth: 46 },
              },
        });

        topMargin = doc.lastAutoTable.finalY - 55;
      });

      if (affichageBulletin.specific) {
        doc.autoTable({
          startY: 60 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            valign: "middle",
            fontStyle: "Bold",
            fontSize: 14,
          },
          cellPadding: 0,
          headStyles: {
            fontSize: 11,
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
          },
          head: [
            [
              affichageBulletin.specificName,
              "Moyenne la plus haute",
              "Moyenne la plus basse",
            ],
          ],
          body: [
            [
              this.bulletin.moyenne,
              this.bulletin.maxMoyenne,
              this.bulletin.minMoyenne,
            ],
          ],
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },

          columnStyles: {
            0: { cellWidth: 61 },
            1: { cellWidth: 61 },
            2: { cellWidth: 61 },
          },
        });

        topMargin = doc.lastAutoTable.finalY - 55;
      }

      if (affichageBulletin.pedagogic || affichageBulletin.personalized) {
        let perMoyenne = "--";
        if (
          affichageBulletin.personalized &&
          this.bulletin.pedaMoyenne != "--" &&
          this.bulletin.moyenne != "--"
        ) {
          const totalCoef =
            affichageBulletin.pedagogicCoef + affichageBulletin.specificCoef;
          perMoyenne = String(
            (
              (Number(this.bulletin.moyenne) * affichageBulletin.specificCoef +
                Number(this.bulletin.pedaMoyenne) *
                  affichageBulletin.pedagogicCoef) /
              totalCoef
            ).toFixed(2)
          );
        }
        const head = [];
        const body = [];
        let tableWidth = 0;
        if (affichageBulletin.pedagogic) {
          head.push(affichageBulletin.pedagogicName);
          body.push(this.bulletin.pedaMoyenne);
          tableWidth += 61;
        }

        if (affichageBulletin.personalized) {
          head.push(affichageBulletin.personalizedName);
          body.push(perMoyenne);
          tableWidth += 61;
        }

        let margin = (pdf_width - tableWidth) / 2;

        doc.autoTable({
          startY: 60 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            valign: "middle",
            fontStyle: "Bold",
            fontSize: 14,
          },
          cellPadding: 0,
          headStyles: {
            fontSize: 11,
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
          },
          head: [head],
          body: [body],
          margin: { left: margin, right: margin },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },

          columnStyles: {
            0: { cellWidth: 61 },
            1: { cellWidth: 61 },
          },
        });

        topMargin = doc.lastAutoTable.finalY - 55;
      }

      this.printLoading = false;

      if (missingMarks.length !== 0)
        Modal.confirm({
          title: this.$t("bulletin.confirmPrintMatieres", {
            count: missingMarks.length,
          }),
          content: (h) => {
            let list = [];
            missingMarks.forEach((s) => {
              list.push(h("li", s));
            });
            return h("ul", list);
          },
          okText: this.$t("bulletin.generateAnyway"),
          okType: "danger",
          cancelText: this.$t("action.annuler"),
          onOk() {
            doc.save("Bulletin " + record.fullName + ".pdf");
          },
          onCancel() {},
        });
      else doc.save("Bulletin pilote" + record.fullName + ".pdf");
      //
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },

    rgbToHex(r, g, b) {
      return (
        "#" +
        this.componentToHex(r) +
        this.componentToHex(g) +
        this.componentToHex(b)
      );
    },

    roundRect(ctx, x, y, width, height, radius, fill, stroke) {
      const cornerRadius = {
        upperLeft: 0,
        upperRight: 0,
        lowerLeft: 0,
        lowerRight: 0,
      };
      if (typeof stroke == "undefined") {
        stroke = true;
      }
      if (typeof radius === "object") {
        for (const side in radius) {
          cornerRadius[side] = radius[side];
        }
      }

      ctx.lineWidth = 1;
      ctx.strokeStyle = `rgb(${this.bulletinColors.color1[0]}, ${this.bulletinColors.color1[1]}, ${this.bulletinColors.color1[2]})`;

      ctx.beginPath();
      ctx.moveTo(x + cornerRadius.upperLeft, y);
      ctx.lineTo(x + width - cornerRadius.upperRight, y);
      ctx.quadraticCurveTo(
        x + width,
        y,
        x + width,
        y + cornerRadius.upperRight
      );
      ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - cornerRadius.lowerRight,
        y + height
      );
      ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
      ctx.quadraticCurveTo(
        x,
        y + height,
        x,
        y + height - cornerRadius.lowerLeft
      );
      ctx.lineTo(x, y + cornerRadius.upperLeft);
      ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
      ctx.closePath();
      if (stroke) {
        ctx.stroke();
      }
      if (fill) {
        ctx.fill();
      }
    },

    drawModuleHeader(doc, ctx, startY, title, style = 1) {
      // module title

      let x = style == 0 ? 80 : 65;
      let y = 63 + startY;
      let width = style == 0 ? 110 : 125;
      doc.setFillColor(...this.bulletinColors.color2);
      this.roundRect(ctx, x, y, width, 8, {}, true, true);
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setDrawColor(0xff, 0xff, 0xff);
      doc.rect(x, y + 20, width + 10, 10, "FD");

      // module header shapes
      x = 158;
      y = 72.6 + startY;
      width = 45;
      doc.setFillColor(...this.bulletinColors.color2);
      this.roundRect(ctx, x, y, width, 7.5, {}, true, true);
      // doc.setFillColor(0xff, 0xff, 0xff);
      // doc.setDrawColor(0xff, 0xff, 0xff);
      // doc.rect(x, y + 9.9, width + 10, 10, "FD");

      x = 148;
      y = 72.6 + startY;
      width = style == 0 ? 26 : 18;
      doc.setFillColor(...this.bulletinColors.color6);
      //العدد / 20
      this.roundRect(ctx, x, y, width, 7.4, {}, true, true);
      // doc.setFillColor(0xff, 0xff, 0xff);
      // doc.setDrawColor(0xff, 0xff, 0xff);
      // doc.rect(x, y + 9.9, width + 10, 10, "FD");

      doc.setLineWidth(0.4);
      doc.setFillColor(...this.bulletinColors.color6);
      doc.setDrawColor(...this.bulletinColors.color2);
      //معدل المجال;
      if (style == 1) doc.rect(134, 72.3 + startY, 15, 8, "FD");
      //توصيّات المدّرس
      doc.rect(
        style == 0 ? 111 : 96,
        72.3 + startY,
        style == 0 ? 46 : 38,
        8,
        "FD"
      );
      doc.rect(style == 0 ? 80 : 65, 72.3 + startY, 15, 8, "FD");
      doc.rect(style == 0 ? 95 : 80, 72.3 + startY, 15, 8, "FD");

      doc.line(155, 80.3 + startY, 202.5, 80.3 + startY);

      // header module texts
      if (/[a-zA-Z]/.test(title)) doc.setFont("Amiri", "Bold");
      else doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(13);
      let text = title;
      doc.setTextColor(0xff, 0xff, 0xff);
      width = doc.getTextWidth(text);
      doc.text(text, 138 - width / 2 - (style == 0 ? 0 : 15), 69 + startY);

      doc.setFont("Amiri", "Bold");
      text = "المادة";
      width = doc.getTextWidth(text);
      doc.text(text, 179, 78 + startY);

      text = "العدد / 20";
      doc.setFontSize(9);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 158 : 151, 78 + startY);

      if (style == 1) {
        text = "معدل\nالمجال";
        doc.setFontSize(9);
        doc.setTextColor(...this.bulletinColors.color2);
        width = doc.getTextWidth(text);
        doc.text(text, 142, 75.2 + startY, "center");
      }

      text = "توصيّات المدّرس";
      doc.setFontSize(11);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 125 : 104, 77.5 + startY);

      text = "أعلى\nعدد بالقسم";
      doc.setFontSize(8);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 103 : 88, 75 + startY, "center");

      text = "أدنى\nعدد بالقسم";
      doc.setFontSize(8);
      doc.setTextColor(...this.bulletinColors.color2);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 87 : 72, 75 + startY, "center");
    },

    drawModuleFooter(doc, ctx, startY, avg) {
      let x = 158;
      let y = startY;
      let width = 35.5;
      doc.setFillColor(...this.bulletinColors.color3);
      doc.setDrawColor(...this.bulletinColors.color3);
      doc.rect(x, y, width + 10, 8.5, "FD");

      x = 111;
      y = startY + 0.4;
      width = 45.5;
      doc.setFillColor(255, 255, 255);

      this.roundRect(ctx, x, y, width, 7.8, {}, true, true);

      // footer text (moyenne)
      doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(13);
      let text = "معدل المجال";
      doc.setTextColor(0xff, 0xff, 0xff);
      doc.text(text, 169, startY + 5.5);

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(14);
      text = String(avg);
      doc.setTextColor(...this.bulletinColors.color4);
      width = doc.getTextWidth(text);
      doc.text(text, 134.75 - width / 2, startY + 5.5);
    },

    drawTrimesterMoyenneAndCards(
      doc,
      ctx,
      startY,
      moy,
      max,
      min,
      style = 1,
      pedaMoyenne,
      affichageBulletin,
      stampImage
    ) {
      const value = style == 1 ? 5 : 0;

      let yPosition = startY + 68.2;

      let text;
      let width;

      if (affichageBulletin.specific) {
        doc.setFillColor(...this.bulletinColors.color5);
        doc.setDrawColor(...this.bulletinColors.color5);
        this.roundRect(
          ctx,
          45 - value * 2,
          yPosition,
          33.5 - value,
          8.2,
          {},
          true,
          false
        );

        doc.setFont("Amiri", "Bold");
        doc.setFontSize(11);
        text = affichageBulletin.specificName;
        doc.setTextColor(0xff, 0xff, 0xff);
        width = doc.getTextWidth(text);
        doc.text(text, 65.5 - value * 3 - width / 2, yPosition + 5.3);

        doc.setLineWidth(0.2);
        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);

        this.roundRect(ctx, 6, yPosition, 38 - value * 2, 7.8, {}, true, true);
        this.roundRect(ctx, 6, yPosition, 19 - value, 7.8, {}, true, true);

        doc.setFont("Amiri", "Bold");
        doc.setFontSize(7);
        doc.setTextColor(...this.bulletinColors.color2);
        doc.text(
          "أعلى\nمعدل بالقسم",
          39 - (value + value / 2),
          yPosition + 3.3,
          "right"
        );
        doc.text(
          "أدنى\nمعدل بالقسم",
          20.2 - value / 2,
          yPosition + 3.3,
          "right"
        );

        // moyenne rect
        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);
        doc.setLineWidth(0.15);
        doc.rect(45 - value * 2, yPosition + 9.3, 33.5 - value, 16.4, "FD");

        doc.setFillColor(...this.bulletinColors.color8);
        doc.rect(6, yPosition + 9.3, 19 - value, 16.4, "FD");
        doc.rect(25 - value, yPosition + 9.3, 19 - value, 16.4, "FD");

        // moyenne text
        doc.setTextColor(...this.bulletinColors.color4);
        doc.setFontSize(16);
        text = String(moy);
        width = doc.getTextWidth(text);
        doc.text(
          text,
          63 - width / 2 - (value * 2 + value / 2),
          yPosition + 18.3
        );

        doc.setFontSize(13);
        text = String(max);
        width = doc.getTextWidth(text);
        doc.text(
          text,
          34.75 - width / 2 - (value + value / 2),
          yPosition + 18.3
        );

        text = String(min);
        width = doc.getTextWidth(text);
        doc.text(text, 15.25 - width / 2 - value / 2, yPosition + 18.3);

        yPosition += 27; //99.2
      }

      if (affichageBulletin.pedagogic) {
        doc.setFillColor(...this.bulletinColors.color5);
        doc.setDrawColor(...this.bulletinColors.color2);
        this.roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);
        doc.setLineWidth(0.05);

        doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

        doc.setFontSize(10);
        text = affichageBulletin.pedagogicName;
        width = doc.getTextWidth(text);
        doc.setTextColor(0xff, 0xff, 0xff);
        doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

        doc.setFontSize(11);
        text = pedaMoyenne ? String(pedaMoyenne) : "--";
        width = doc.getTextWidth(text);
        doc.setTextColor(...this.bulletinColors.color4);
        doc.text(text, 45 - width / 2 - value * 2, yPosition + 14.3);

        yPosition += 20; //110
      }

      if (affichageBulletin.personalized) {
        doc.setFillColor(...this.bulletinColors.color5);
        doc.setDrawColor(...this.bulletinColors.color2);
        this.roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

        doc.setFillColor(...this.bulletinColors.color6);
        doc.setDrawColor(...this.bulletinColors.color2);
        doc.setLineWidth(0.05);
        doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

        doc.setFontSize(10);
        text = affichageBulletin.personalizedName;
        width = doc.getTextWidth(text);
        doc.setTextColor(0xff, 0xff, 0xff);
        doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

        let perMoyenne = "--";
        if (pedaMoyenne != "--" && moy != "--") {
          const totalCoef =
            affichageBulletin.pedagogicCoef + affichageBulletin.specificCoef;
          perMoyenne = String(
            (
              (Number(moy) * affichageBulletin.specificCoef +
                Number(pedaMoyenne) * affichageBulletin.pedagogicCoef) /
              totalCoef
            ).toFixed(2)
          );
        }
        doc.setFontSize(11);
        width = doc.getTextWidth(perMoyenne);
        doc.setTextColor(...this.bulletinColors.color4);
        doc.text(perMoyenne, 45 - width / 2 - value * 2, yPosition + 14.5);

        yPosition += 20; // 121
      }

      doc.setLineWidth(0.15);

      doc.setFillColor(...this.bulletinColors.color6);
      doc.setDrawColor(...this.bulletinColors.color2);

      yPosition -= 36;

      doc.rect(6, yPosition + 42, 72.5 - value * 3, 55);
      doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

      doc.rect(6, yPosition + 106, 72.5 - value * 3, 30);
      doc.rect(40.75 - value * 3, yPosition + 102, 36.75, 8, "FD");

      doc.setFontSize(13);
      doc.setTextColor(...this.bulletinColors.color2);

      text = "مدير)ة( المدرسة";
      doc.text(text, 45 - value * 3, yPosition + 43);

      doc.setTextColor(67, 62, 63);
      doc.text(
        this.schoolDetails.headMaster +
          "\n" +
          moment(this.bulletinDate).format("YYYY/MM/DD"),
        30 - value * 3,
        yPosition + 51
      );

      doc.setTextColor(...this.bulletinColors.color2);
      doc.setFontSize(16);

      text = "إمضاء الولي";
      doc.text(text, 48 - value * 3, yPosition + 107);

      //stamp
      if (stampImage && stampImage.src) {
        try {
          doc.addImage(stampImage, "PNG", 10, yPosition + 61, 40, 30);
        } catch (e) {}
      }
      //end stamp
    },

    async drawBulletinPeda(
      doc,
      ctx,
      student,
      bulletinData,
      affichageBulletin,
      stampImage
    ) {
      const style = this.selectedLevel > 2 ? 1 : 0;
      const record = student;

      const pdf_width = doc.internal.pageSize.width;
      let width = 0;
      let width2 = 0;

      // ending header line
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.8);
      doc.line(0, 40 - 12, pdf_width, 40 - 12);

      // trimester rounded rect title
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(pdf_width / 2 - 25, 34 - 12, 50, 12, 4, 4, "FD");

      // trimester text
      doc.setFontSize(16);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(...this.bulletinColors.color5);
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "الثلاثي الأول";
          break;
        case "2":
          title = "الثلاثي الثاني";
          break;
        case "3":
          title = "الثلاثي الثالث";
          break;
      }
      doc.setFont("Noto Sans Arabic", "Bold");
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 41.2 - 12);

      const className = bulletinData.classroomName;

      let studentLabel =
        record.gender === "male" ? " : التلميذ" : " : التلميذة";

      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);

      doc.setTextColor(0, 0, 0);

      text = this.schoolDetails.ArabicName
        ? " المدرسة الابتدائيّة " + this.schoolDetails.ArabicName
        : "............................... : المدرسة الابتدائيّة";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 12);
      text = ` ${this.schoolarYearName}  : السنة الدراسيّة`;
      width = doc.getTextWidth(text);
      doc.text(text, 10, 12);

      let text = studentLabel;
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 17.5);

      text = student.fullName;
      doc.setFont("Amiri", "normal");
      doc.setFontSize(13);
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - width2 - 12, 17.5);
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      // student classroom
      text = " : القسم";
      doc.setTextColor(0, 0, 0);
      width = doc.getTextWidth(text);
      width2 = doc.getTextWidth(className);
      doc.text(text, 13 + width2, 17.5);

      doc.text(className, 12, 17.5);

      const cellToDelete = {};
      let sumCoef = 0;
      let topMargin = -21;

      let tableSize = 9;
      switch (this.selectedLevel) {
        case 1:
          tableSize = 11;
          break;
        case 2:
          tableSize = 11;
          break;
        case 3:
          tableSize = 11;
          break;
        case 4:
          tableSize = 10.5;
          break;
        default:
          break;
      }

      student.modules.forEach((m) => {
        let arr = [];
        let array = [];
        sumCoef += m.coef;

        this.drawModuleHeader(doc, ctx, topMargin, m.name, style);
        topMargin += 17.2;

        m.subjects.forEach((c) => {
          let subFormattedName = c.name;
          if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
            let indexOfFirst = c.name.indexOf(" و ");
            subFormattedName =
              c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
          }
          arr[6] = {
            content: subFormattedName,
            styles: {
              halign: "right",
              fillColor: this.rgbToHex(...this.bulletinColors.color7),
              fontSize: tableSize,
              textColor: "#ffffff",
              lineColor: [229, 229, 229],
            },
          };
          arr[5] = {
            content: c.mark,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          arr[4] = cellToDelete;
          if (style == 1) arr[3] = cellToDelete;
          arr[2] = cellToDelete;

          //best mark
          arr[1] = {
            content: c.max,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          //lowest mark
          arr[0] = {
            content: c.min,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          array.push(arr);
          arr = [];
        });

        //end shifting

        array[0][2] = {
          content: "",
          rowSpan: array.length,
          styles: {
            lineColor: [...this.bulletinColors.color5],
          },
        };
        if (style == 1)
          array[0][3] = {
            content: m.appreciation.trim(),
            rowSpan: array.length,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: m.appreciation.length > 15 ? 10 : 12,
              lineColor: [...this.bulletinColors.color5],
            },
          };

        array[0][4] = {
          content: style ? m.moyenne : m.appreciation.trim(),
          rowSpan: array.length,
          styles: {
            halign: "center",
            fontSize: m.appreciation.length > 15 && !style ? 10 : 12,
            valign: style ? "center" : "top",
            lineColor: [...this.bulletinColors.color5],
          },
        };

        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }

        doc.autoTable({
          startY: 64 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          body: array,
          margin: { left: style == 1 ? 65 : 80, bottom: 0 },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },
          columnStyles:
            style == 1
              ? {
                  0: { cellWidth: 15 },
                  1: { cellWidth: 15 },
                  2: { cellWidth: 1 },
                  3: { cellWidth: 38 },
                  4: { cellWidth: 15 },
                  5: { cellWidth: 17 },
                  6: { cellWidth: 38 },
                }
              : {
                  0: { cellWidth: 15 },
                  1: { cellWidth: 15 },
                  2: { cellWidth: 1 },
                  3: { cellWidth: 46 },
                  4: { cellWidth: 17 },
                  5: { cellWidth: 30 },
                },
        });

        if (style == 0)
          this.drawModuleFooter(
            doc,
            ctx,
            doc.lastAutoTable.finalY + 1,
            m.moyenne
          );

        topMargin = doc.lastAutoTable.finalY - 61.4 + (style == 0 ? 11 : 0);
      });

      this.drawTrimesterMoyenneAndCards(
        doc,
        ctx,
        -27,
        student.moyenne,
        bulletinData.maxMoyenne,
        bulletinData.minMoyenne,
        style,
        student.pedaMoyenne,
        affichageBulletin,
        stampImage
      );
    },

    async prePrintPedaOne(student) {
      this.$gtag.event("Imp Bulletin format peda", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;

      await this.updateBulletin();

      let missingMarks = [];

      this.bulletin.modules.forEach((m) => {
        m.subjects.forEach((c) => {
          if (c.mark == "--") missingMarks.push(c.name);
        });
      });

      const _this = this;

      if (missingMarks.length !== 0)
        Modal.confirm({
          title: this.$t("bulletin.confirmPrintMatieres", {
            count: missingMarks.length,
          }),
          content: (h) => {
            let list = [];
            missingMarks.forEach((s) => {
              list.push(h("li", s));
            });
            return h("ul", list);
          },
          okText: this.$t("bulletin.generateAnyway"),
          okType: "danger",
          cancelText: this.$t("action.annuler"),
          async onOk() {
            _this.chooseBulletinDate().then(() => {
              _this.printOnePeda(student);
            });
          },
          onCancel() {
            _this.printLoading = false;
          },
        });
      else {
        await this.chooseBulletinDate();
        this.printOnePeda(student);
      }
    },

    async printOnePeda(student) {
      this.printLoading = true;
      const style = this.selectedLevel > 2 ? 1 : 0;

      let affichageBulletin;

      await apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: "specific",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            affichageBulletin = res.data[0];
          } else {
            affichageBulletin = {
              specific: true,
              pedagogic: false,
              personalized: false,
              specificName: "معدل",
            };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        });

      const record =
        student || this.activeData.find((s) => s._id == this.selectedStudent);

      const doc = new JsPDF();
      const ctx = doc.context2d;
      const pdf_width = doc.internal.pageSize.width;
      const pdf_height = doc.internal.pageSize.height;
      let width = 0;
      let width2 = 0;

      // ending header line
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.8);
      doc.line(0, 40 - 12, pdf_width, 40 - 12);

      // trimester rounded rect title
      doc.setDrawColor(...this.bulletinColors.color5);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(pdf_width / 2 - 25, 34 - 12, 50, 12, 4, 4, "FD");

      // trimester text
      doc.setFontSize(16);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(...this.bulletinColors.color5);
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "الثلاثي الأول";
          break;
        case "2":
          title = "الثلاثي الثاني";
          break;
        case "3":
          title = "الثلاثي الثالث";
          break;
      }
      doc.setFont("Noto Sans Arabic", "Bold");
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 41.2 - 12);

      const className = this.bulletin.classroomName;

      let studentLabel =
        record.gender === "male" ? " : التلميذ" : " : التلميذة";

      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);

      text = this.schoolDetails.ArabicName
        ? " المدرسة الابتدائيّة " + this.schoolDetails.ArabicName
        : "............................... : المدرسة الابتدائيّة";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 12);
      text = ` ${this.schoolarYearName}  : السنة الدراسيّة`;
      width = doc.getTextWidth(text);
      doc.text(text, 10, 12);

      let text = studentLabel;
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 17.5);

      text = this.bulletin.fullName;
      doc.setFont("Amiri", "normal");
      doc.setFontSize(13);
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - width2 - 12, 17.5);
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      // student classroom
      text = " : القسم";
      doc.setTextColor(0, 0, 0);
      width = doc.getTextWidth(text);
      width2 = doc.getTextWidth(className);
      doc.text(text, 13 + width2, 17.5);

      doc.text(className, 12, 17.5);

      const cellToDelete = {};
      let topMargin = -21;

      let tableSize = 9;
      switch (this.selectedLevel) {
        case 1:
          tableSize = 11;
          break;
        case 2:
          tableSize = 11;
          break;
        case 3:
          tableSize = 11;
          break;
        case 4:
          tableSize = 10.5;
          break;
        default:
          break;
      }
      this.bulletin.modules.forEach((m) => {
        let arr = [];
        let array = [];

        this.drawModuleHeader(doc, ctx, topMargin, m.name, style);
        topMargin += 17.2;

        m.subjects.forEach((c) => {
          let subFormattedName = c.name;
          if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
            let indexOfFirst = c.name.indexOf(" و ");
            subFormattedName =
              c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
          }
          arr[6] = {
            content: subFormattedName,
            styles: {
              halign: "right",
              fillColor: this.rgbToHex(...this.bulletinColors.color7),
              fontSize: tableSize,
              textColor: "#ffffff",
              lineColor: [229, 229, 229],
            },
          };

          arr[5] = {
            content: c.mark,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          arr[4] = cellToDelete;
          if (style == 1) arr[3] = cellToDelete;
          arr[2] = cellToDelete;

          //best mark
          arr[1] = {
            content: c.max,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          //lowest mark
          arr[0] = {
            content: c.min,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [...this.bulletinColors.color5],
            },
          };

          array.push(arr);
          arr = [];
        });

        array[0][2] = {
          content: "",
          rowSpan: array.length,
          styles: {
            lineColor: [...this.bulletinColors.color5],
          },
        };
        if (style == 1)
          array[0][3] = {
            content: m.appreciation.trim(),
            rowSpan: array.length,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: m.appreciation.length > 15 ? 10 : 12,
              lineColor: [...this.bulletinColors.color5],
            },
          };

        array[0][4] = {
          content: style ? m.moyenne : m.appreciation.trim(),
          rowSpan: array.length,
          styles: {
            halign: "center",
            fontSize: m.appreciation.length > 15 && !style ? 10 : 12,
            valign: style ? "center" : "top",
            lineColor: [...this.bulletinColors.color5],
          },
        };

        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }

        doc.autoTable({
          startY: 64 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          body: array,
          margin: { left: style == 1 ? 65 : 80, bottom: 0 },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },

          columnStyles:
            style == 1
              ? {
                  0: { cellWidth: 15 },
                  1: { cellWidth: 15 },
                  2: { cellWidth: 1 },
                  3: { cellWidth: 38 },
                  4: { cellWidth: 15 },
                  5: { cellWidth: 17 },
                  6: { cellWidth: 38 },
                }
              : {
                  0: { cellWidth: 15 },
                  1: { cellWidth: 15 },
                  2: { cellWidth: 1 },
                  3: { cellWidth: 46 },
                  4: { cellWidth: 17 },
                  5: { cellWidth: 30 },
                },
          drawRow: function (row, data) {
            row.height = 2;
          },
        });

        if (style == 0)
          this.drawModuleFooter(
            doc,
            ctx,
            doc.lastAutoTable.finalY + 1,
            m.moyenne
          );

        topMargin = doc.lastAutoTable.finalY - 61.4 + (style == 0 ? 11 : 0);
      });

      let cardStartY = 0;
      switch (this.selectedLevel) {
        case 1:
          cardStartY = -15;
          break;
        case 2:
          cardStartY = -15;
          break;
        case 3:
          cardStartY = -22;
          break;
        case 4:
          cardStartY = -22;
          break;
        case 5:
          cardStartY = -22;
          break;
        case 6:
          cardStartY = -22;
          break;
        default:
          break;
      }
      //stamp
      let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
      const stampImage = new Image(30);
      if (stamp) stampImage.src = stamp;
      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
          //doc.addImage(stampImage, "PNG", 10, 180, 40, 40);
        } catch (e) {}
      }
      //end stamp
      this.drawTrimesterMoyenneAndCards(
        doc,
        ctx,
        -27,
        this.bulletin.moyenne,
        this.bulletin.maxMoyenne,
        this.bulletin.minMoyenne,
        style,
        this.bulletin.pedaMoyenne,
        affichageBulletin,
        stampImage
      );

      this.printLoading = false;
      doc.save("Bulletin " + record.fullName + ".pdf");
    },

    async printAllBulletinsPeda() {
      this.$gtag.event("Imp all Bulletins pedaFormat", {
        event_category: "Impression PDF",
        event_label: "Bulletin pilote-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;
      const doc = new JsPDF();
      const ctx = doc.context2d;
      let bulletinData;
      let firstPage = true;
      let affichageBulletin;

      await apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: "specific",
          },
        })
        .then((res) => {
          if (res.data[0]) {
            affichageBulletin = res.data[0];
          } else {
            affichageBulletin = {
              specific: true,
              pedagogic: false,
              personalized: false,
              specificName: "معدل",
            };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        });

      await apiClient
        .get(
          "/specificBulletin/all/" +
            this.selectedClasse +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          bulletinData = data;
        });

      //stamp
      let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
      const stampImage = new Image(30);
      if (stamp) stampImage.src = stamp;
      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
        } catch (e) {}
      }
      //end stamp

      const missingStudent = this.classroomMarksStats.students
        ? this.classroomMarksStats.students[this.selectedClasse]
        : [];
      const className = this.selectedClassName;

      const _this = this;

      if (missingStudent && missingStudent.length !== 0)
        Modal.confirm({
          title: this.$t("bulletin.confirmPrintEleves", {
            count: missingStudent.length,
          }),
          content: (h) => {
            let list = [];
            missingStudent.forEach((s) => {
              list.push(h("li", s));
            });
            return h("ul", list);
          },
          okText: this.$t("bulletin.generateAnyway"),
          okType: "danger",
          cancelText: this.$t("action.annuler"),
          onOk() {
            _this.chooseBulletinDate().then(() => {
              bulletinData.students.forEach((student) => {
                if (!firstPage) {
                  doc.addPage();
                }
                _this.drawBulletinPeda(
                  doc,
                  ctx,
                  student,
                  bulletinData,
                  affichageBulletin,
                  stampImage
                );
                firstPage = false;
                _this.printLoading = false;
              });
              doc.save(`Bulletin ${className}.pdf`);
            });
          },
          onCancel() {
            _this.printLoading = false;
          },
        });
      else {
        this.chooseBulletinDate().then(() => {
          bulletinData.students.forEach((student) => {
            if (!firstPage) {
              doc.addPage();
            }
            this.drawBulletinPeda(
              doc,
              ctx,
              student,
              bulletinData,
              affichageBulletin
            );
            firstPage = false;
            this.printLoading = false;
          });
          doc.save(`Bulletin ${className}.pdf`);
        });
      }
    },
  },
};
</script>
<style scoped>
.arabic-font {
  font-size: 14px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
</style>
